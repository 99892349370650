import { Box, Container, Heading, Link, Text } from "@radix-ui/themes";
import { isRouteErrorResponse } from "@remix-run/react";
import { InfillaLogo } from "../InfillaLogo";
import { captureRemixErrorBoundaryError } from "@sentry/remix";

import { Link as RemixLink } from "@remix-run/react";
import { PropsWithChildren } from "react";
import { MailtoLink } from "../MailtoLink";

interface ErrorLayoutProps extends PropsWithChildren {
  title?: string;
  /** An html Link element to show instead of the logo */
  parentLink?: React.ReactNode;
  error: Error;
}

/**
 * Standard page content for handling servers error (i.e. `ErrorBoundary`)
 *
 * Note that this also handles reporting the error to Sentry so every error page should always be using it
 */
export const ErrorLayout: React.FC<ErrorLayoutProps> = ({
  title,
  parentLink,
  error,
  children,
}) => {
  // report to Sentry
  captureRemixErrorBoundaryError(error);
  // report to console
  // eslint-disable-next-line no-console
  console.error(error);

  if (!title) {
    title = isRouteErrorResponse(error)
      ? `${error.status}: ${error.statusText}`
      : "Internal Server Error";
  }

  return (
    <Container my="8" size="2">
      <Box>
        {parentLink ? (
          <Link asChild>{parentLink}</Link>
        ) : (
          <RemixLink to="/">
            <InfillaLogo size="2" />
          </RemixLink>
        )}
      </Box>

      <Heading size="4" mt="2">
        {title}
      </Heading>
      {children}
    </Container>
  );
};

interface DefaultContentProps {
  error: Error;
}
/**
 * Content to show if there's no more specific info about the error
 */
export const DefaultContent: React.FC<DefaultContentProps> = ({ error }) => {
  const emailSubject: string = isRouteErrorResponse(error)
    ? `[${error.status} Error]`
    : `[Error]`;

  // Posting to `/logout` will clear the `_session` cookie
  const signOut = () => {
    fetch("/logout", { method: "post" }).then(() => location.reload());
  };

  return (
    <>
      <Text as="p" my="2">
        Sometimes{" "}
        <Link onClick={signOut} href="#">
          clearing your cookies
        </Link>{" "}
        will help.
      </Text>
      <Text as="p" my="2">
        Contact{" "}
        <Link asChild>
          <MailtoLink to="support@infilla.com" subject={emailSubject} />
        </Link>{" "}
        if you still need help.
      </Text>
    </>
  );
};
